@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  

@font-face {
  font-family: "AeonikBold";
  src: url("../public/Fonts/Aeonik-Bold.ttf");
}


@font-face {
  font-family: "AeonikMedium";
  src: url("../public/Fonts/Aeonik-Medium.ttf");
}

@font-face {
  font-family: "AeonikRegular";
  src: url("../public/Fonts/Aeonik-Regular.ttf");
}

@font-face {
  font-family: "Grifter";
  src: url("../public/Fonts/grifterbold.ttf");
}

body {
    max-width:2500px;
    margin: auto;
    min-width: 320px;
}

.swiper-pagination-bullet-active{
  background: #4B5DFF;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide{
  opacity: .4;
  max-width: 100%;
}

.videoview-slider{
  width: 100%;
  height: calc(100vh - 84px);
}

.swiper-slide.swiper-slide-active{
  opacity: 1;
}

.vertical-slider .swiper-slide {
  font-size: 18px;
  background: #fff;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-disabled.swiper-nav {
  opacity:0.4;
  cursor:default;
}

.swiper-nav{
  opacity:1;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
}


@media (max-width: 1024px) {
  .videoview .swiper-nav{
    opacity: 0;
  }

  .videoview:hover .swiper-nav{
    opacity: 1;
  }

  .videoview .swiper-button-disabled.swiper-nav{
    opacity: 0.4;
  }

}